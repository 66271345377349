@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');


.hamburger{
  width:40px;
  border: transparent !important;
}




.navbar-toggler{
  border: transparent !important;
}




.bac-img-about{
  background-image: url("./images/about.svg");
  background-position:left;
  background-repeat: no-repeat;
  background-size: cover;
  }

  .logo{
    width:45px;
  }

.bac-colo-why{
  background-color: #FAFAFA;
  width: 100%;
}

.contact-background{
  background-image: url(images/contact.png);
  background-position:center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top:30px;
}


.contact-background-coprate{
  background-image: url(images/suit.jpeg);
  background-position:center;
  background-repeat: no-repeat;
  background-size: cover;
  padding:100px 70px 100px 70px;
  border-radius:35px;
}

.background-footer{
  background-color: #F9FAFB;
  width: 100%;
  margin-top:35px;
  padding-top:100px;
  padding-bottom:40px;
  padding-left:30px;
}

.line{
  border-top: 1px solid #EAECF0;
  margin-top:25px;
  margin-bottom: 40px;
}

.intro-header{
  font-family: "Outfit", sans-serif;
  font-weight:600;
  font-size:70px;
  margin-top:80px;
  line-height:80px;
  margin-bottom:40px;
}

.colored-cut{
background: -webkit-linear-gradient( #DD5004, #EE1C2D);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sentence{
  color: #475467;
  font-family: "Outfit", sans-serif;
  font-size:16px;
  margin-top:30px;
  font-weight:400;
  padding-right:70px;
  margin-bottom:80px;
}

.hero-image{
  margin-left:72px !important;
}

.bac-clients-color{
  background-image: linear-gradient(to right, #EE1C2D, #DD5004);
  padding:10px 20px 10px 20px;
  border-radius:30px;
  color: white;
  font-family: "Outfit", sans-serif;
  font-weight: 500;
}

.client-logo{
  width:310px;
  margin-left:50px;
  margin-top:-10px;
}

.about-us{
  font-family: "Outfit", sans-serif;
  color: #101828;
  text-align: center;
  font-weight: 700;  
  font-size:40px;
  margin-bottom: 60px;
  margin-top: 30px;
}

.sentence-fluid{
  color: #475467;
  font-family: "Outfit", sans-serif;
  font-size:16px;
  margin-top:45px;
  font-weight:400;
  padding-right:180px;
  margin-bottom:80px;
}

.icon-a{
  width:58px;
}

.business-header{
  font-family: "Outfit", sans-serif;
  color: #101828;
   font-weight: 700;  
  font-size:40px;
  margin-bottom: 30px;
  margin-top: 30px;
}

.card-golden{
  background-color: #F2F4F7;
  width:80%;
  border-radius:40px;
  margin-bottom:80px;
 }

 .golden-card-img{
  float: right !important;
  width:170px;
  margin-top:-202px;
 }

 .wrapper{
  padding:15px 180px 19px 35px;
 }

 .service-icon{
  width:55px;
  margin-left:35px;
  margin-top:-30px;
  }

  .card-sentence{
    font-family: "Outfit", sans-serif;
    font-weight: 400;
    color: #475467;
    font-size:14px; 
  }

  .card-header{
    font-family: "Outfit", sans-serif;
    font-weight: 600;
    color: #101828;
    margin-bottom:20px;
    margin-top:0px;
}

.card-call-to-action{
  font-family: "Outfit", sans-serif;
    font-weight: 600;
    color: #101828;
    font-size:15px;
  }


  .why{
    font-family: "Outfit", sans-serif;
    color: #101828;
    text-align: center;
    font-weight: 700;  
    font-size:40px;
    margin-bottom: 60px;
    margin-top: 60px;
  }

  .hero-image-2{
    margin-left:154px !important;
    margin-top: -30px;
    width:550px;
  }

  .why-icon{
    margin-left:40px;
    margin-right:15px;
  }

  .why-header{
    color: #101828;
    font-family: "Outfit", sans-serif;
    font-weight:700;
  }

  .why-sentence{
    margin-left:110px;
    color: #475467;
    font-size:16px;
    font-family: "Outfit", sans-serif;
    margin-bottom:50px;
  }

  .btn-golden{
    margin-left:110px;
    color: white;
    background-image: linear-gradient(to right, #EE1C2D, #E53619, #DD5004);
    border: transparent;
    padding:15px 40px 15px 40px;
    border-radius:30px;
    font-family: "Outfit", sans-serif;
    font-weight: 700;
    font-size:15px;
  }

  .arrow{
    margin-left:10px;
  }

  .header-contact{
    font-family: "Outfit", sans-serif;
    color: white; 
    font-size:69px;
    font-weight: 700;
  }

  .sentence-contact{
    font-family: "Outfit", sans-serif;
    color: white;
    font-size: 18px;
    font-weight: 400; 
    padding-right:550px;
    margin-top: 20px;
    margin-bottom: 60px;
  }

  input{
    width:95%;
    padding:15px 15px 15px 28px;
    border: transparent;
    border-radius: 35px;
    margin-bottom:35px;
    color: black;
    font-family: "Outfit", sans-serif;
    font-size:16px;
    font-weight:400;
  }

  .btn-contact{
    background-color:#292D32;
    width:95%;
    padding:15px 15px 15px 28px;
    border: transparent;
    border-radius: 35px;
    font-family: "Outfit", sans-serif;
    font-size:16px;
    font-weight:700;
    color: white;
  }

  .li-2{
    list-style: none !important;
    font-family: "Outfit", sans-serif;
    font-size:14px;
    color: #374151;
    font-weight: 400;
    margin-bottom: 18px;
    text-decoration: none !important;
  }

  .link{
    text-decoration: none !important; 
  }

  .footer-header{
    font-family: "Outfit", sans-serif;
    font-weight: 700;  
    color: #374151;
    font-size:16px;
    margin-bottom:35px;
}

.rights{
  font-family: "Outfit", sans-serif;
  color: #374151;
  font-weight:400;
  font-size:14px; 
}

.mail-footer{
  font-weight: 700;
  text-decoration: underline;
}

.termsof{
  font-family: "Outfit", sans-serif;
  color: #374151;
  font-weight:700;
  font-size:14px; 
}

.callicon{
  width:20px;
  margin-right:10px;
}

.callicon-2{
  width:15px;
  margin-right:10px;
}



.menu-padding{
  margin-left:400px;
}

.bg-body-tertiary{
  background-color: white !important;
  border-bottom: 0.8px solid #E1E2E5;
  margin-bottom: 30px;
  padding-bottom:15px;
  padding-top:15px;
}

.nav-button-golden{
  background-color: #292D32;
  padding: 10px 25px 10px 25px;
  color: white;
  font-family: "Outfit", sans-serif;
  font-weight: 500;
  font-size:14px;
  border-radius:35px;
  border: transparent !important;
  margin-right: 50px;
}

.nav-link{
  font-family: "Outfit", sans-serif;
  margin-right:35px;  
  font-weight: 500;
  color: #475467;
  font-size:15px;
}

.dropdown-menu{
  border: 0.8px solid #EE1C2D;
  border-radius: 12px;
  font-family: "Outfit", sans-serif;
  font-size: 14px;
  font-weight: 400;
 }

 .dropdown-item{
  padding: 5px 20px 5px 20px;
 }

 /** contact css **/

 .contact-header{
text-align: center;
margin-top: 50px;
color: #101828;
font-family: "Outfit", sans-serif;
font-size: 63px;
font-weight: 600;
}

.contact-sentence{
  text-align: center;
  font-family: "Outfit", sans-serif;
  color: #475467;
  font-weight: 400;
  margin-top:20px;
}

.map{
  margin-top:50px;
}

.frame-padd{
  padding-left:5px;
}

.contact-icon{
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 70px;
  width:63px;
}

.intro-header-golden{
  text-align: center;
  font-family: "Outfit", sans-serif;
  color: #101828;
  font-weight: 700;
  margin-top:23px;
  font-size:18px;
}


.intro-sentence{
  text-align: center;
  font-family: "Outfit", sans-serif;
  color: #475467;
  font-weight: 400;
}

.cc{
  text-align: center;
  font-family: "Outfit", sans-serif;
  color: #E53619;
  font-weight: 700;
  font-size:15px; 
 
}


.bac-contact{
  background-image: linear-gradient(#DD5004, #EE1C2D);
  width:100%;
  margin-top:60px;
}

.office{
  width:80%;
 }

 .add-header{
  margin-left: -50px;
  margin-top:50px;
  color: #E1E2E5;
  font-family: "Outfit", sans-serif;
  font-weight: 700;
  font-size:18px;
}

.add-sub-header{
  margin-left: -50px;
  color: white;
  font-family: "Outfit", sans-serif;
  font-weight: 700;
}

.add-sentence{
  margin-left: -50px;
  margin-top:25px;
  color:#E9D7FE;
  font-family: "Outfit", sans-serif;
  font-weight: 400;
  font-size:18px;
}

.add-location-1{
  margin-left: -50px; 
  font-family: "Outfit", sans-serif;
  color: white;
  font-family: "Outfit", sans-serif;
  font-weight: 700;
}

.add-place-1{
  margin-left: -50px; 
  color: #E9D7FE;
  font-family: "Outfit", sans-serif;
  font-weight: 400;

}

.add-location{

  font-family: "Outfit", sans-serif;
  color: white;
  font-family: "Outfit", sans-serif;
  font-weight: 700;
}

.add-place{

  color: #E9D7FE;
  font-family: "Outfit", sans-serif;
  font-weight: 400;

}



 /**end **/

.bac-why{
  background-image: url('./images/Why.png');
  background-repeat: no-repeat;
  
  background-size: cover;
}

.whypics{
 margin-top:60px;
 margin-bottom:80px; 
}

.why-heading{
  margin-left:80px;
  font-family: "Outfit", sans-serif;
  font-size:16px;
  color: #101828;
  font-weight: 700;
}

.why-sentence-main{
  margin-left:80px;
  font-family: "Outfit", sans-serif;
  color: #475467;
  font-size:14px;
  padding-right:50px;
  margin-top:10px;
  margin-bottom: 70px;
}

.whyicon{
  margin-top:2px;
  float:left;
  width:45px;
}

.our-client{
  text-align: center;
  font-family: "Outfit", sans-serif;
  font-weight: 600;
  margin-top:10px;
  font-size: 30px;
}

.our-client-sentence{
  text-align: center;
  font-family: "Outfit", sans-serif;
  color: #475467;
  margin-top: 20px;
  }

  .logo-clients{
    width:65px;
    margin-right:100px;
    margin-top:30px;

  }


  .logo-clients-2{
    width:100px;
    margin-right:100px;
    margin-top:30px;
}

.logo-clients-3{
  width:70px;
  margin-right:100px;
  margin-top:30px;
}

.logo-clients-4{
  width:60px;
  margin-right:100px;
  margin-top:30px;
}

.logo-clients-5{
  width:90px;
  margin-right:100px;
  margin-top:30px;
}

.logo-clients-6{
  width:95px;
  margin-right:100px;
  margin-top:30px;
}

.logo-clients-7{
  width:90px;
  margin-right:100px;
  margin-top:30px;
}

/**end**/


/**service **/

.abstract{
  background-image: url(./images/abstract.png);
  background-repeat: no-repeat;
  background-size:cover;
  background-position:center;
}

.service-tags{
  background-image: linear-gradient(to right, #EE1C2D, #DD5004);
  padding:10px 20px 10px 20px;
  border-radius:30px;
  color: white;
  font-family: "Outfit", sans-serif;
  font-weight: 500;
}


.service-header{

  text-align:left;
margin-top: 50px;
color: #101828;
font-family: "Outfit", sans-serif;
font-size: 63px;
font-weight: 600;

}

.service-sentence{
  color: #475467;
  font-family: "Outfit", sans-serif;
  font-size:16px;
  margin-top:40px;
  font-weight:400;
  padding-right:70px;
  margin-bottom:80px;
}

.expor{
  margin-top:-110px;
  width:500px;
  float:right;
  margin-right:-71px;
  margin-bottom:20px;
}

.liner{
  border-top: 0.8px solid #E1E2E5;
  width:100%;
  margin-top: 40px;
}

.header-second{
  font-family: "Outfit", sans-serif;
  color: #101828;
  padding-left:300px;
  font-weight: 600;  
  font-size:30px;
  margin-bottom: 40px;
  margin-top: 70px;
}

.sentence-second{
  color: #475467;
  font-family: "Outfit", sans-serif;
  font-size:16px;
  margin-top:40px;
  font-weight:400;
  padding-left:300px;
  padding-right:280px;
  margin-bottom:80px;

}


.header-second-2{
  font-family: "Outfit", sans-serif;
  color: #101828;
text-align: center;
  font-weight: 600;  
  font-size:30px;
  margin-bottom: 80px;
  margin-top: 80px;
}


.service-trade{

  text-align:center;
margin-top: 50px;
color: #101828;
font-family: "Outfit", sans-serif;
font-size: 63px;
font-weight: 600;

}

.service-sentence-trade{
  color: #475467;
  font-family: "Outfit", sans-serif;
  font-size:16px;
  margin-top:40px;
  font-weight:400;
  text-align: center;
  padding-left:290px;
  padding-right:290px;
  margin-bottom:80px;
}


.img-plastic{
  border-radius:30px;
}


/*** mobile media query **/

@media only screen and (min-device-width : 320px) and (max-device-width : 480px){

  html, body {
    overflow-x: hidden;
  }


  .img-plastic{
    border-radius:30px;
    margin-top:30px;
  }


.service-trade{

  text-align:left;
margin-top: 50px;
color: #101828;
font-family: "Outfit", sans-serif;
font-size: 40px;
font-weight: 600;
padding-left:30px;

}

.service-sentence-trade{
  color: #475467;
  font-family: "Outfit", sans-serif;
  font-size:16px;
  margin-top:40px;
  font-weight:400;
  text-align: left;
  padding-left:30px;
  padding-right:20px;
  margin-bottom:10px;
}



  .service-tags{
    background-image: linear-gradient(to right, #EE1C2D, #DD5004);
    padding:10px 20px 10px 20px;
    border-radius:30px;
    color: white;
    font-family: "Outfit", sans-serif;
    font-weight: 500;
  }

  .expor{
   display: none !important;
  }

  .header-second{
    font-family: "Outfit", sans-serif;
    color: #101828;
    padding-left:20px;
    font-weight: 600;  
    font-size:30px;
    margin-bottom: 40px;
    margin-top: 70px;
   
}
  
  .sentence-second{
    color: #475467;
    font-family: "Outfit", sans-serif;
    font-size:16px;
    margin-top:40px;
    font-weight:400;
    padding-left:20px;
    padding-right:20px;
    margin-bottom:80px;
  
  }



.service-header{

  text-align:left;
margin-top: 50px;
color: #101828;
font-family: "Outfit", sans-serif;
font-size: 35px;
font-weight: 600;
}

.service-sentence{
  color: #475467;
  font-family: "Outfit", sans-serif;
  font-size:16px;
  margin-top:20px;
  font-weight:400;
  padding-right:20px;
  padding-left:10px;
  margin-bottom:80px;
}

.bac-clients-color{
  display: none;
}


  .add-header{
    margin-left: 20px;
    margin-top:50px;
    color: #E1E2E5;
    font-family: "Outfit", sans-serif;
    font-weight: 700;
    font-size:18px;
  }
  
  .add-sub-header{
    margin-left: 20px;
    color: white;
    font-family: "Outfit", sans-serif;
    font-weight: 700;
  }
  
  .add-sentence{
    margin-left: 20px;
    margin-top:25px;
    color:#E9D7FE;
    font-family: "Outfit", sans-serif;
    font-weight: 400;
    font-size:18px;
  }
  
  .add-location-1{
    margin-left: 20px; 
    font-family: "Outfit", sans-serif;
    color: white;
    font-family: "Outfit", sans-serif;
    font-weight: 700;
  }
  
  .add-place-1{
    margin-left: 20px; 
    color: #E9D7FE;
    font-family: "Outfit", sans-serif;
    font-weight: 400;
  
  }
  
  .add-location{
  
    font-family: "Outfit", sans-serif;
    color: white;
    font-family: "Outfit", sans-serif;
    font-weight: 700;
    margin-left: 20px; 
  }
  
  .add-place{
  
    color: #E9D7FE;
    font-family: "Outfit", sans-serif;
    font-weight: 400;
    margin-left: 20px; 
  
  }
  


  .office{
    width:100%;
   }


  .intro-header{
    font-family: "Outfit", sans-serif;
    font-weight:600;
    font-size:38px;
    margin-top:30px;
    line-height:45px;
    margin-bottom:40px;
    padding-left:20px;
  }


  .sentence{
    color: #475467;
    font-family: "Outfit", sans-serif;
    font-size:14px;
    margin-top:30px;
    font-weight:400;
    padding-right:20px;
    padding-left:20px;
    margin-bottom:30px;
  }


  .bac-clients-color{
    background-image: linear-gradient(to right, #EE1C2D, #DD5004);
    padding:5px 10px 5px 10px;
    border-radius:30px;
    color: white;
    font-family: "Outfit", sans-serif;
    font-weight: 500;
    margin-left:20px;
  }
  
  .client-logo{
    width:170px;
    margin-left:30px;
    margin-top:-10px;
  }


  .hero-image{
    margin-left:18px !important;
    margin-top:50px;
    width:350px;
  }


  .about-us{
    font-family: "Outfit", sans-serif;
    color: #101828;
    text-align: center;
    font-weight: 700;  
    font-size:25px;
    margin-bottom: 30px;
    margin-top: 30px;
  }
  

  .icon-a{
    width:50px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .sentence-fluid{
    color: #475467;
    font-family: "Outfit", sans-serif;
    font-size:15px;
    margin-top:40px;
    font-weight:400;
    padding-right:10px;
    padding-left:20px;
    margin-bottom:40px;
  }

  .business-header{
    font-family: "Outfit", sans-serif;
    color: #101828;
     font-weight: 700;  
    font-size:30px;
    margin-bottom: 30px;
    margin-top: 10px;
    margin-left:20px;
  }
  


.card-golden{
  background-color: #F2F4F7;
  width:100%;
  border-radius:40px;
  margin-bottom:80px;
 }

 .service-icon{
  width:45px;
  margin-left:35px;
  margin-top:-30px;
  }

  .sentence{
    margin-bottom:60px;
  }


  .golden-card-img{
 display: none;
   }

   .wrapper{
    padding:10px 15px 19px 35px;
   }

   .bac-colo-why{
    background-color: #FAFAFA;
    width: 100%;
    margin-top:-50px;
  }


  .why{
    font-family: "Outfit", sans-serif;
    color: #101828;
    text-align: left;
    font-weight: 700;  
    font-size:30px;
    margin-bottom: 60px;
    margin-top: 60px;
    margin-left:28px;
  }


  .why-icon{
    margin-left:20px;
    margin-right:10px;
  }


  .why-sentence{
    margin-left:28px;
    margin-right:18px;
    color: #475467;
    font-size:14px;
    font-family: "Outfit", sans-serif;
    margin-bottom:30px;
    margin-top:20px;
  }


  .btn-golden{
    
    color: white;
    background-image: linear-gradient(to right, #EE1C2D, #E53619, #DD5004);
    border: transparent;
    padding:15px 30px 15px 30px;
    border-radius:30px;
    font-family: "Outfit", sans-serif;
    font-weight: 700;
    font-size:15px;
    width:90%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top:30px;
    margin-bottom:30px;
  }
  

  .hero-image-2{
  display: block;
  margin-left: auto !important;
  margin-right: auto !important;
    margin-top: 15px;
    width:550px;
    padding-left:50px;
  }


  .header-contact{
    font-family: "Outfit", sans-serif;
    color: white; 
    font-size:30px;
    font-weight: 700;
  }

  .contact-background-coprate{
    background-image: url(images/suit.jpeg);
    background-position:center;
    background-repeat: no-repeat;
    background-size: cover;
    padding:40px 25px 20px 30px;
    border-radius:35px;
  }



  .sentence-contact{
    font-family: "Outfit", sans-serif;
    color: white;
    font-size: 18px;
    font-weight: 400; 
    padding-right:0px;
    margin-top: 20px;
    margin-bottom: 30px;
  }


  .logo{
    width:40px;
    margin-left: 20px;
  }


  .menu-padding{
    margin-left:-10px;
    margin-top:20px;
    margin-bottom:30px;
  }


  .nav-button-golden{
    background-color: #292D32;
    padding: 10px 25px 10px 25px;
    color: white;
    font-family: "Outfit", sans-serif;
    font-weight: 500;
    font-size:14px;
    border-radius:35px;
    border: transparent !important;
    margin-right: 10px;
   float: right !important;
  }


  .contact-header{
    text-align: center;
    margin-top: 50px;
    color: #101828;
    font-family: "Outfit", sans-serif;
    font-size: 40px;
    font-weight: 600;
    }


    .why-heading{
      margin-left:20px;
      font-family: "Outfit", sans-serif;
      font-size:16px;
      color: #101828;
      font-weight: 700;
      margin-top:60px;
    }
    
    .why-sentence-main{
      margin-left:20px;
      font-family: "Outfit", sans-serif;
      color: #475467;
      font-size:14px;
      padding-right:20px;
      margin-top:10px;
      margin-bottom: 45px;
    }
    
    .whyicon{
      margin-top:2px;
     margin-left:25px;
      width:40px;
    }


    .our-client{
      text-align:left;
      font-family: "Outfit", sans-serif;
      font-weight: 600;
      margin-top:10px;
      font-size: 22px;
      margin-left:19px;
    }
    
    .our-client-sentence{
      text-align: left;
      font-family: "Outfit", sans-serif;
      color: #475467;
      margin-top: 10px;
       margin-left:19px;
      }
 


      .logo-clients{
        width:50px;
        margin-right:20px;
        margin-top:30px;
    
      }
    
    
      .logo-clients-2{
       width:90px;
        margin-right:20px;
        margin-top:30px;
    }
    
    .logo-clients-3{
      width:50px;
      margin-right:20px;
      margin-top:30px;
    }
    
    .logo-clients-4{
      width:50px;
      margin-right:10px;
      margin-top:30px;
    }
    
    .logo-clients-5{
      width:60px;
      margin-right:10px;
      margin-top:30px;
    }
    
    .logo-clients-6{
      width:95px;
      margin-right:25px;
      margin-top:30px;
      margin-left:20px;
    }
    
    .logo-clients-7{
      width:90px;
      margin-right:20px;
      margin-top:30px;
    }

}


/**end **/




/** media query for mobile landscape mode ** /

/* Target devices with a max width of 1024px in landscape mode */
@media only screen and (max-width: 1024px) and (orientation: landscape) {

 


  .img-plastic{
    border-radius:30px;
    margin-top:30px;
  }

.service-trade{

  text-align:left;
margin-top: 50px;
color: #101828;
font-family: "Outfit", sans-serif;
font-size: 40px;
font-weight: 600;
padding-left:30px;

}

.service-sentence-trade{
  color: #475467;
  font-family: "Outfit", sans-serif;
  font-size:16px;
  margin-top:40px;
  font-weight:400;
  text-align: left;
  padding-left:30px;
  padding-right:20px;
  margin-bottom:10px;
}


  .header-second{
    font-family: "Outfit", sans-serif;
    color: #101828;
    padding-left:20px;
    font-weight: 600;  
    font-size:30px;
    margin-bottom: 40px;
    margin-top: 70px;
   
}
  
  .sentence-second{
    color: #475467;
    font-family: "Outfit", sans-serif;
    font-size:16px;
    margin-top:40px;
    font-weight:400;
    padding-left:20px;
    padding-right:20px;
    margin-bottom:80px;
  
  }


  .service-tags{
    background-image: linear-gradient(to right, #EE1C2D, #DD5004);
    padding:10px 20px 10px 20px;
    border-radius:30px;
    color: white;
    font-family: "Outfit", sans-serif;
    font-weight: 500;
  }

  .expor{
   display: none !important;
  }


.service-header{

  text-align:left;
margin-top: 50px;
color: #101828;
font-family: "Outfit", sans-serif;
font-size: 35px;
font-weight: 600;
}

.service-sentence{
  color: #475467;
  font-family: "Outfit", sans-serif;
  font-size:16px;
  margin-top:20px;
  font-weight:400;
  padding-right:20px;
  padding-left:10px;
  margin-bottom:80px;
}



  .logo-clients{
    width:50px;
    margin-right:50px;
    margin-top:30px;

  }


  .logo-clients-2{
   width:90px;
    margin-right:50px;
    margin-top:30px;
}

.logo-clients-3{
  width:50px;
  margin-right:50px;
  margin-top:30px;
}

.logo-clients-4{
  width:50px;
  margin-right:50px;
  margin-top:30px;
}

.logo-clients-5{
  width:60px;
  margin-right:50px;
  margin-top:30px;
}

.logo-clients-6{
  width:95px;
  margin-right:50px;
  margin-top:30px;
  margin-left:20px;
}

.logo-clients-7{
  width:90px;
  margin-right:50px;
  margin-top:30px;
}


  .intro-header{
    font-family: "Outfit", sans-serif;
    font-weight:600;
    font-size:38px;
    margin-top:30px;
    line-height:45px;
    margin-bottom:40px;
    padding-left:20px;
  }


  .sentence{
    color: #475467;
    font-family: "Outfit", sans-serif;
    font-size:14px;
    margin-top:30px;
    font-weight:400;
    padding-right:20px;
    padding-left:20px;
    margin-bottom:30px;
  }


  .bac-clients-color{
    background-image: linear-gradient(to right, #EE1C2D, #DD5004);
    padding:5px 10px 5px 10px;
    border-radius:30px;
    color: white;
    font-size: 14px;
    font-family: "Outfit", sans-serif;
    font-weight: 500;
    margin-left:20px;
  }
  
  .client-logo{
    width:170px;
    margin-left:30px;
    margin-top:-10px;
  }


  .hero-image{
    margin-top:50px;
    width:500px;
  }


  .about-us{
    font-family: "Outfit", sans-serif;
    color: #101828;
    text-align: center;
    font-weight: 700;  
    font-size:25px;
    margin-bottom: 30px;
    margin-top: 30px;
  }
  

  .icon-a{
    width:50px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .sentence-fluid{
    color: #475467;
    font-family: "Outfit", sans-serif;
    font-size:15px;
    margin-top:40px;
    font-weight:400;
    padding-right:10px;
    padding-left:20px;
    margin-bottom:40px;
  }

  .business-header{
    font-family: "Outfit", sans-serif;
    color: #101828;
     font-weight: 700;  
    font-size:30px;
    margin-bottom: 30px;
    margin-top: 10px;
    margin-left:20px;
  }
  


.card-golden{
  background-color: #F2F4F7;
  width:100%;
  border-radius:40px;
  margin-bottom:80px;
 }

 .service-icon{
  width:45px;
  margin-left:35px;
  margin-top:-30px;
  }

  .sentence{
    margin-bottom:60px;
  }


  .golden-card-img{
 display: none;
   }

   .wrapper{
    padding:10px 15px 19px 35px;
   }

   .bac-colo-why{
    background-color: #FAFAFA;
    width: 100%;
    margin-top:-50px;
  }


  .why{
    font-family: "Outfit", sans-serif;
    color: #101828;
    text-align: left;
    font-weight: 700;  
    font-size:30px;
    margin-bottom: 60px;
    margin-top: 60px;
    margin-left:28px;
  }


  .why-icon{
    margin-left:20px;
    margin-right:10px;
  }


  .why-sentence{
    margin-left:28px;
    margin-right:18px;
    color: #475467;
    font-size:14px;
    font-family: "Outfit", sans-serif;
    margin-bottom:30px;
    margin-top:20px;
  }


  .btn-golden{
    
    color: white;
    background-image: linear-gradient(to right, #EE1C2D, #E53619, #DD5004);
    border: transparent;
    padding:15px 30px 15px 30px;
    border-radius:30px;
    font-family: "Outfit", sans-serif;
    font-weight: 700;
    font-size:15px;
    width:90%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top:30px;
    margin-bottom:30px;
  }
  

  .hero-image-2{
  display: block;
  margin-left: auto !important;
  margin-right: auto !important;
    margin-top: 15px;
    width:550px;
    padding-left:50px;
  }


  .header-contact{
    font-family: "Outfit", sans-serif;
    color: white; 
    font-size:30px;
    font-weight: 700;
  }

  .contact-background-coprate{
    background-image: url(images/suit.jpeg);
    background-position:center;
    background-repeat: no-repeat;
    background-size: cover;
    padding:40px 25px 20px 30px;
    border-radius:35px;
  }



  .sentence-contact{
    font-family: "Outfit", sans-serif;
    color: white;
    font-size: 18px;
    font-weight: 400; 
    padding-right:0px;
    margin-top: 20px;
    margin-bottom: 30px;
  }


  .logo{
    width:40px;
    margin-left: 20px;
  }


  .menu-padding{
    margin-left:-10px;
    margin-top:20px;
    margin-bottom:30px;
  }


  .nav-button-golden{
    background-color: #292D32;
    padding: 10px 25px 10px 25px;
    color: white;
    font-family: "Outfit", sans-serif;
    font-weight: 500;
    font-size:14px;
    border-radius:35px;
    border: transparent !important;
    margin-right: 10px;
   float: right !important;
  }
 


}


/** end **/ 



/** css for tablets **/





/** end **/


